/** @jsx jsx */
import React, {useState} from 'react'
import {
    jsx,
    Label,
    Input,
    Textarea,
    Box, 
    Button
  } from 'theme-ui'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

const ContactForm = () => {
   
    const [formSuccess, setFormSuccess] = useState(false)
    const [formError, setFormError] = useState(false)

    const ContactSchema = Yup.object().shape({
        name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
        
        email: Yup.string()
        .email('Invalid email')
        .required('Required'),
        
        message: Yup.string()
            .min(2, 'Please provide a message!')
            .required('Required'),
    });

    const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    }

 
    return(
        

        <Box sx={{
            bg: 'white',
            p: 3,
            marginBottom: 4
        }}> 
        <p>To contact Portia directly you can use the form below:-</p>
            <Formik
              initialValues={{ name: '', email: '', message: '' }}
              validationSchema={ContactSchema}
              onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                fetch("/", {                                 
                  method: 'POST',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  body: encode({
                    'form-name': 'contact',
                    ...values,
                  }),
                })
                  .then(() => {
                    //alert('Success!')
                    setSubmitting(false)
                    setFormSuccess(true)
                    resetForm()
                  })
                  .catch(error => {
                    //alert('Error: Please Try Again!')                            
                    setSubmitting(false)
                    setFormError(true)
                  })
              }}
              render={({
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleReset,
              }) => (
                <Form className='form'
                  name='contact'
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                  data-netlify='true'
                  data-netlify-honeypot='bot-field'
                  sx={{
                    my: 3,
                    '& .help': {
                        color: 'red',
                        fontStyle: 'italic',
                        mt:1
                    },
                    
                    '& .field': {
                        mb: 3
                    },
                    '& .input': {
                        lineHeight: 2,
                        border: '1px solid #ccc',
                        height: '40px',
                        fontSize: 2,
                        width: '50%',
                        minWidth: '200px',
                        mt: 2,
                        px: 2
                    },
                    '& .input-textarea': {
                        width: '90%',
                        border: '1px solid #ccc',
                        height: '100px',
                        mt: 2,
                        px: 2,
                        fontSize: 2,
                        fontFamily: 'inherit',
                    },
                    '& .is-danger.input, & .is-danger.input-textarea': {
                        borderColor: '#ff3860'
                    },
                    '& .input:hover, & .is-hovered.input, & .is-hovered.textarea, & .select select.is-hovered, & .select select:hover, & .textarea:hover': {
                        borderColor: '#b5b5b5'
                    },
                    '& button': {
                        borderRadius: 'none',
                        backgroundColor: 'primary',
                        color: 'lightText',
                        border: 'none',
                        fontSize: 3,
                        py: 2,
                        px: 3,
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }
                }}
                >
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                <Field name="name" placeholder="Your Name" className={errors.name && touched.name ? `input is-danger` : `input`} />
                {errors.name && touched.name ? (
                    <p className="help is-danger">{errors.name}</p>
                ) : null}
                </div>
            </div>
    
            <div className="field">
                <label className="label">Email</label>
                <div className="control">
                <Field name="email" type="email" placeholder="Your Email" className={errors.email && touched.email ? `input is-danger` : `input`} />
                {errors.email && touched.email ? <p className="help is-danger">{errors.email}</p> : null}
                </div>
            </div>
    
            <div className="field">
                <label className="label">Message</label>
                <div className="control">
                <Field name="message" component="textarea" placeholder="Your Message" className={errors.message && touched.message ? `input-textarea is-danger` : `input-textarea`} />
                {errors.message && touched.message ? (
                    <p className="help is-danger">{errors.message}</p>
                ) : null}
                </div>
            </div>
            
            
          <div className='buttons'>
            <Button as="input" type='reset' value='Clear' variant="secondary"
              className='button' mr={1}/>
            <Button as="input" name='submit' type='submit' disabled={isSubmitting} value='Send Message'
              className='button is-dark' />
          </div>
                </Form>
              )}
           /> {/*end of formik self closing tag*/}
          
           { formSuccess && (
            <div className="notification is-success">
              
              Form submitted successfully.  I will try and respond in the next 24 hours.
            </div>
          )
          }
      
          { formError && (
            <div className="notification is-danger">
              
              Form failed.  There appears to be an error.  Please try again later or reach out on social media.
            </div>
          )
          }
          </Box>
    )
}

export default ContactForm;