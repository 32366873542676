/** @jsx jsx */
import { jsx, Flex, Grid } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React from "react"
import NavMenuDesktop from "../components/navMenuDesktop"
import NavMenuMobile from "../components/navMenuMobile"
import Footer from "../components/footer"
import { Document, Page } from "react-pdf"
import { FaImdb, FaTwitter, FaFacebook, FaVimeo } from "react-icons/fa"
import LogoSpotlight from "../images/logo-spotlight.svg"
import LogoMandy from "../images/logo-mandy-outlines.svg"
import { Link } from "gatsby"
import ContactForm from "../components/contactFormik"
import SEO from "../components/seo"

const ContactPage = ({ data }) => {
  const {
    link_imdb,
    link_spotlight,
    link_vimeo,
    link_mandy,
    link_twitter,
    link_facebook,
  } = data.contact.frontmatter
  return (
    <>
      <SEO title="Contact" />
      <NavMenuMobile />
      <Grid
        gap={2}
        columns={["none", "2fr 1fr", "3fr 1fr"]}
        sx={{
          // bg: lighten('primary', 0.35),
          bg: "primaryBG",
          px: 3,
        }}
      >
        <section sx={{ minHeight: "100vh" }}>
          <h1
            sx={{
              color: "primary",
              mt: ["75px", 3],
            }}
          >
            Contact
          </h1>
          {/* <Document file={data.contact.frontmatter.cv_pdf.publicURL}>
                    <Page pageNumber={1}/>
                </Document> */}
          <div
            dangerouslySetInnerHTML={{ __html: data.contact.html }}
            sx={{
              bg: "white",
              padding: 3,
              "& p > span": {
                width: "25%",
                marginLeft: "-10px !important",
              },
            }}
          />
          <div
            sx={{
              my: 3,
              textAlign: ["center", "center", "left"],
              "& svg": {
                width: "1em",
                fill: "currentcolor",
                stroke: "currentcolor",
                "& path": {
                  fill: "currentcolor",
                },
              },
              "& a": {
                transition: "color 0.3s",
                color: "primary",
                fontSize: 5,
                m: 3,
                mx: [2, 3],

                "&:hover, &:focus": {
                  color: darken("primary", 0.5),
                },
              },
            }}
          >
            <a href={link_imdb}>
              <FaImdb />
            </a>
            <a href={link_spotlight}>
              <LogoSpotlight />
            </a>
            <a href={link_vimeo}>
              <FaVimeo />
            </a>
            <a href={link_twitter}>
              <FaTwitter />
            </a>
            <a href={link_facebook}>
              <FaFacebook />
            </a>
            <a href={link_mandy}>
              <LogoMandy />
            </a>
          </div>
          <ContactForm />
        </section>
        <section>
          <NavMenuDesktop navPosition="right" />
        </section>
      </Grid>
      <Footer navPosition="right" />
    </>
  )
}

export default ContactPage

export const query = graphql`
  query {
    contact: markdownRemark(
      frontmatter: { templateKey: { eq: "page-contact" } }
    ) {
      id
      frontmatter {
        email
        link_spotlight
        link_imdb
        link_facebook
        link_twitter
        link_mandy
        link_vimeo
        cv_pdf {
          id
          publicURL
        }
      }
      html
    }
  }
`
